.ant-layout-sider ,.ant-menu-dark, .ant-layout-sider-trigger ,.ant-menu-sub.ant-menu-inline{
    background: #1A1E23 !important;
}
.ant-layout-sider{
    position: fixed !important;
    left: 0px;
    right: auto;
    z-index: 9999;
    height: 100%;
}
.ant-layout-header{
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 9998;
}
.ant-layout-content{
    margin: 0;
    position: fixed;
    width: 100%;
    inset: 0;
    padding: 78px 16px 16px 266px;
    overflow: auto;
    &::-webkit-scrollbar {
    width: 4px;
    }
    &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
    background: #edeef1;
    border-radius: 4px;
    }
}
.ant-layout-sider.ant-layout-sider-collapsed+.ant-layout .ant-layout-content{
    padding: 78px 16px 16px 96px;
}
button {
    font-weight: 500 !important;
}

.inline-btn {
    border: 2px solid #0071F1;
    color: #0071F1;
}

.ant-form-item-required {
    &::before {
        position: absolute;
        right: 0;
    }
}

.editor-height {
    .ck-content.ck-editor__editable {
        min-height: calc(100vh - 375px);
    }
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
    display: none !important;
}

.upload-lang {
    .ant-upload {
        width: 100%;
    }
}

.card-full {
    margin: 0 18px 0 0;

    .ant-card {
        width: 100% !important;

        .ant-avatar {
            width: 50px;
            height: 50px;
        }
    }
}

:where(.css-dev-only-do-not-override-1r287do).ant-image .ant-image-mask:hover {
    opacity: 0;
}

.multiple-img {
    .ant-upload-list-item-container {
        width: 200px !important;
        height: 200px !important;
    }

    .ant-upload.ant-upload-select {
        width: 200px !important;
        height: 200px !important;
    }

    .ant-upload-list-item-error {
        border-color: #000 !important;
    }
}

.ant-image-mask:hover {
    opacity: 0 !important;
}

.upload-img {
    img {
        width: 102px !important;
        padding: 0;
        margin: 0 8px;
    }
}

.PhoneInputInput {
    &:focus-visible {
        outline: none !important;
    }
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header {
    align-items: center;
}
.ant-carousel .slick-prev, .ant-carousel .slick-next {
    color: #000000;
    opacity: 1;
}
.ant-select-selector{
    cursor: pointer !important;
}
.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(4px); 
  }

  .ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-expand-icon {
    position: absolute;
    right: 0;
    padding: 10px!important;
}